// token details
const contracts = {
    lpStaking: {
        contract: "secret1cr4ksw99fagg7lyvjuqhhxrernkxadtlzq9m6l",
        hash: "dfdcda1416cb83e9db751863039c5ee94db70b7824dae9bd6fff72c1eb780452",
    },
};


export default contracts;


